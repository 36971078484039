
  import { Component, Prop } from 'vue-property-decorator'

  import Row from '../row.vue'
  import Financing from '../expandable/financing'

  import { SaleOrder } from '@/entities/sales'

@Component({ components: { Row, Financing } })
  export default class Loans extends Row {
  @Prop({ type: Boolean, default: false }) opened!: boolean;

  declare value: SaleOrder;
  initial: number = 0

  get loans () {
    const { value: { financings: loans = [], financing }, bind, opened } = this
    const filterLoans = loans.filter(loan => Boolean(loan?.id))

    this.initial = financing?.initial
    return filterLoans.map(loan => ({
      ...bind,
      opened,
      value: loan,
    })).reverse()
  }
  }
